import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import localeCs from '@angular/common/locales/cs';
import { registerLocaleData } from '@angular/common';
import { ApplicationStateService } from './domains/shared/domain/application/application-state.service';

@Component({
  imports: [RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'jpdesignstudio-crm-web';

  protected readonly appState = inject(ApplicationStateService);

  constructor() {
    registerLocaleData(localeCs);
    this.appState.loadUsersToSystem();
  }
}
