import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    {
        path: 'administration',
        loadChildren: () => import('./modules/layouts/administration/routes'),
    },
    {
        path: 'auth/callback',
        redirectTo: '/administration/dashboard'
    },
    {
        path: '**',
        redirectTo: '/administration/dashboard'
    }

];
