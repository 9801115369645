import {
  ApplicationConfig,
  inject,
  provideZoneChangeDetection,
  provideAppInitializer,
  ErrorHandler,
  APP_INITIALIZER,
  LOCALE_ID
} from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withViewTransitions,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { NgModule, isDevMode } from '@angular/core';
import { AuthConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import {
  StatehandlerProcessorService,
  StatehandlerProcessorServiceImpl,
} from './domains/system/domain/services/statehandler-processor.service';
import {
  StatehandlerService,
  StatehandlerServiceImpl,
} from './domains/system/domain/services/statehandler.service';
import { StorageService } from './domains/system/domain/services/storage.service';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, split } from '@apollo/client/core';
import { provideApollo } from 'apollo-angular';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import * as Sentry from '@sentry/angular';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind, OperationTypeNode } from 'graphql';
import { UserPermissionsService } from './domains/system/domain/services/user-permissions.service';


const authConfig: AuthConfig = {
  scope:
    'openid profile email offline_access urn:zitadel:iam:user:resourceowner urn:zitadel:iam:org:project:id:zitadel:aud urn:zitadel:iam:org:project:roles',
  responseType: 'code',
  oidc: true,
  clientId: '301634747522678787',
  issuer: 'https://auth.praceprofirmy.cz', // eg. https://acme-jdo9fs.zitadel.cloud
  redirectUri: environment.redirectUri,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  requireHttps: environment.requireHttps, // required for running locally
};

const stateHandlerFn = (stateHandler: StatehandlerService) => {
  return () => {
    return stateHandler.initStateHandler();
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      appRoutes,
      withViewTransitions(),
      withComponentInputBinding()
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [
          'https://auth.praceprofirmy.cz/admin/v1',
          'https://auth.praceprofirmy.cz/management/v1',
          'https://auth.praceprofirmy.cz/auth/v1/',
          'https://auth.praceprofirmy.cz/v2/',
          environment.graphqlUrl,
          environment.apiUrl,
        ],
        sendAccessToken: true,
      },
    }),
    provideLuxonDateAdapter(),
    provideAppInitializer(() => {
      const initializerFn = stateHandlerFn(inject(StatehandlerService));
      return initializerFn();
    }),
    provideApollo(() => {
      const oAuthService = inject(OAuthService);
      const httpLink = inject(HttpLink);
      // Create an http link:
      const http = httpLink.create({
        uri: environment.graphqlUrl,
      });

      // Create a WebSocket link:
      const apolloWsLink = new GraphQLWsLink(
        createClient({
          url: environment.graphqlWsUrl,
          connectionParams: () => ({
            Authorization: `Bearer ${oAuthService.getAccessToken()}`,
          }),
        })
      );

      // Using the ability to split links, you can send data to each link
      // depending on what kind of operation is being sent
      const link = split(
        // Split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === Kind.OPERATION_DEFINITION &&
            definition.operation === OperationTypeNode.SUBSCRIPTION
          );
        },
        apolloWsLink,
        http
      );

      return {
        link,
        cache: new InMemoryCache(),
        // other options...
      };
    }),
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideAnimationsAsync(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [UserPermissionsService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
  ],
};
